import { useLanguage } from '../../context/LanguageContext';

const Firm = () => {
  const { translations, language } = useLanguage();

  return (
    <section id="firm" className="px-8 py-32 relative">
      <div className="container">
        <div className="grid md:grid-cols-2 gap-16 items-center mb-32">
          <div>
            <h2 className="font-light leading-[1.1] tracking-tight space-y-1">
              <span className="block">{translations.firm.title.line1}</span>
              <span className="brush-accent block">{translations.firm.title.line2}</span>
              <span className="block">{translations.firm.title.line3}</span>
              <span className="block">{translations.firm.title.line4}</span>
            </h2>
          </div>
          <div className="flex justify-center">
            <img 
              src="/logo-white.png" 
              alt={language === 'en'
                ? "Gamboa Abogados law firm logo - Seven generations of legal tradition in Colombia"
                : "Logo de Gamboa Abogados - Siete generaciones de tradición legal en Colombia"
              }
              className="w-3/4 h-auto opacity-90"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-24">
          <div className="text-gray-400 text-lg leading-relaxed">
            {translations.firm.description1}
          </div>
          <div className="text-gray-400 text-lg leading-relaxed">
            {translations.firm.description2}
          </div>
        </div>

        {/* Foundation Section */}
        <div className="mt-40 border-t border-white/10 pt-8">
          <div className="flex justify-between items-start">
            <h3 className="font-light leading-tight">
              {translations.firm.principles.title.line1}
              <br />
              {translations.firm.principles.title.line2}
              <br />
              {translations.firm.principles.title.line3}
            </h3>
          </div>

          <div className="mt-24 grid md:grid-cols-3 gap-16">
            {translations.firm.principles.list.map((principle, index) => (
              <div key={index}>
                <h4 className="text-accent text-lg mb-6">{principle.title}</h4>
                <p className="text-gray-400 leading-relaxed">
                  {principle.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 border-t border-white/10" />
    </section>
  );
};

export default Firm; 