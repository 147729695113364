import { useLanguage } from '../../context/LanguageContext';

const Footer = () => {
  const { translations, language } = useLanguage();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="py-12 bg-background/30">
      <div className="container px-8">
        <div className="grid md:grid-cols-3 gap-8">
          <div>
            <h4 className="text-xl font-light mb-4">Gamboa Abogados</h4>
            <p className="text-text-gray">
              Seven generations of legal tradition, modern solutions for today's challenges.
            </p>
            <div className="mt-6">
              <a 
                href="https://laworld.com/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-block hover:opacity-80 transition-opacity"
              >
                <img 
                  src="/laworld-logo.png" 
                  alt="LAWorld Network" 
                  className="h-16 w-auto"
                />
              </a>
            </div>
          </div>
          
          <div>
            <h4 className="text-xl font-light mb-4">{language === 'en' ? 'Address' : 'Dirección'}</h4>
            <p className="text-text-gray">
              Carrera 7 # 76-35<br />
              Oficina 501<br />
              Bogotá D.C. 110221<br />
              Colombia
            </p>
          </div>

          <div className="flex flex-col items-center justify-center">
            <a 
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=mq_X0h3k40uRzGIUBFdzADORfNkkVx1LiNTmTD2_qVFUQjg1OUcwQTM5U0VKNkw5SldER0gzSURZQy4u"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center px-6 py-2 border border-white/20 hover:bg-accent hover:border-accent transition-all duration-300 tracking-wide mb-4"
            >
              {translations.nav.workWithUs}
            </a>
            <div className="flex gap-4 text-sm text-text-gray">
              <a 
                href="https://www.gamboaabogados.com/privacy-policy" 
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-accent transition-colors"
              >
                {language === 'en' ? 'Privacy Policy' : 'Política de Privacidad'}
              </a>
              <span>|</span>
              <a 
                href="https://www.gamboaabogados.com/data-treatment-policy" 
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-accent transition-colors"
              >
                {language === 'en' ? 'Data Treatment Policy' : 'Política de Tratamiento de Datos'}
              </a>
            </div>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-white/10 flex justify-between items-center">
          <div className="text-sm text-text-gray">
            © {currentYear} Gamboa Abogados. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 