import { BrowserRouter } from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext';
import Navbar from './components/layout/Navbar';
import Hero from './components/sections/Hero';
import Firm from './components/sections/Firm';
import Services from './components/sections/Services';
import People from './components/sections/People';
import Testimonials from './components/sections/Testimonials';
import Footer from './components/layout/Footer';
import AttorneyProfile from './pages/AttorneyProfile';
import ServiceDetail from './pages/ServiceDetail';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useLanguage } from './context/LanguageContext';

function App() {
  const { language } = useLanguage();
  const canonicalUrl = 'https://galegal.co' + (language === 'en' ? '/en' : '');
  
  return (
    <HelmetProvider>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>
          {language === 'en' 
            ? "Gamboa Abogados | Boutique Law Firm in Colombia"
            : "Gamboa Abogados | Firma de Abogados Boutique en Colombia"
          }
        </title>
        <meta name="description" content={
          language === 'en'
            ? "Gamboa Abogados: Seven generations of legal tradition in Colombia. Specialized in litigation, arbitration, negotiation and commercial law."
            : "Gamboa Abogados: Siete generaciones de tradición legal en Colombia. Especialistas en litigio, arbitraje, negociación y derecho comercial."
        } />
        
        {/* Canonical URL */}
        <link rel="canonical" href={canonicalUrl} />
        
        {/* Hreflang Tags */}
        <link rel="alternate" hrefLang="es" href="https://galegal.co" />
        <link rel="alternate" hrefLang="en" href="https://galegal.co/en" />
        <link rel="alternate" hrefLang="x-default" href="https://galegal.co" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={
          language === 'en'
            ? "Gamboa Abogados | Boutique Law Firm in Colombia"
            : "Gamboa Abogados | Firma de Abogados Boutique en Colombia"
        } />
        <meta property="og:description" content={
          language === 'en'
            ? "Gamboa Abogados: Seven generations of legal tradition in Colombia. Specialized in corporate law, commercial law, and arbitration."
            : "Gamboa Abogados: Siete generaciones de tradición legal en Colombia. Especialistas en derecho corporativo, comercial y arbitraje."
        } />
        <meta property="og:image" content="https://galegal.co/logo-white.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={canonicalUrl} />
        <meta property="twitter:title" content={
          language === 'en'
            ? "Gamboa Abogados | Boutique Law Firm in Colombia"
            : "Gamboa Abogados | Firma de Abogados Boutique en Colombia"
        } />
        <meta property="twitter:description" content={
          language === 'en'
            ? "Gamboa Abogados: Seven generations of legal tradition in Colombia. Specialized in corporate law, commercial law, and arbitration."
            : "Gamboa Abogados: Siete generaciones de tradición legal en Colombia. Especialistas en derecho corporativo, comercial y arbitraje."
        } />
        <meta property="twitter:image" content="https://galegal.co/logo-white.png" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LawFirm",
            "name": "Gamboa Abogados",
            "image": "https://galegal.co/logo-white.png",
            "description": language === 'en' 
              ? "Seven generations of legal tradition in Colombia. Specialized in corporate law, commercial law, and arbitration."
              : "Siete generaciones de tradición legal en Colombia. Especialistas en derecho corporativo, comercial y arbitraje.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Carrera 7 # 76-35",
              "addressLocality": "Bogotá",
              "addressRegion": "D.C.",
              "postalCode": "110221",
              "addressCountry": "CO"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "4.6570",
              "longitude": "-74.0550"
            },
            "url": "https://galegal.co",
            "telephone": "+57-1-XXX-XXXX",
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "09:00",
              "closes": "18:00"
            },
            "sameAs": [
              "https://www.linkedin.com/company/gamboa-abogados/"
            ]
          })}
        </script>
      </Helmet>
      <LanguageProvider>
        <BrowserRouter>
          <div className="min-h-screen bg-[#2A2A2A] text-white">
            <Navbar />
            <main className="text-white">
              <Hero />
              <Firm />
              <Services />
              <People />
              <Testimonials />
            </main>
            <Footer />
          </div>
        </BrowserRouter>
      </LanguageProvider>
    </HelmetProvider>
  );
}

export default App;