import { useState, useEffect } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { language, setLanguage, translations } = useLanguage();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navLinks = [
    { href: '#firm', label: translations.nav.firm },
    { href: '#services', label: translations.nav.services },
    { href: '#people', label: translations.nav.people },
    { href: '#testimonials', label: translations.nav.testimonials },
    { href: 'https://forms.office.com/Pages/ResponsePage.aspx?id=mq_X0h3k40uRzGIUBFdzADORfNkkVx1LiNTmTD2_qVFUQjg1OUcwQTM5U0VKNkw5SldER0gzSURZQy4u', label: translations.nav.contact, target: '_blank' },
    { href: '#contact', label: translations.nav.workWithUs }
  ];

  return (
    <nav 
      className={`
        fixed w-full z-50 px-8 py-6 
        transition-all duration-300 ease-in-out
        ${isScrolled ? 'bg-[#2A2A2A] shadow-lg' : 'bg-transparent'}
      `}
    >
      <div className="max-w-[1400px] mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-12">
          {/* Logo */}
          <a 
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="text-2xl cursor-pointer"
          >
            <span 
              className="font-century tracking-[0.25em] cursor-pointer"
              style={{ fontFamily: 'Century Gothic, sans-serif' }}
            >
              GAMBOA
            </span>
          </a>
          
          {/* Desktop Nav Links */}
          <div className="hidden md:flex space-x-8">
            <a href="#firm" className="nav-link font-neutrif">{translations.nav.firm}</a>
            <a href="#services" className="nav-link font-neutrif">{translations.nav.services}</a>
            <a href="#people" className="nav-link font-neutrif">{translations.nav.people}</a>
            <a href="#testimonials" className="nav-link font-neutrif">{translations.nav.testimonials}</a>
          </div>
        </div>
        
        {/* Right side nav items */}
        <div className="flex items-center space-x-6">
          {/* LinkedIn Button */}
          <a
            href="https://www.linkedin.com/company/gamboa-abogados/"
            target="_blank"
            rel="noopener noreferrer"
            className="hidden md:inline-flex items-center hover:opacity-70 transition-opacity cursor-pointer"
          >
            <svg className="w-6 h-6 cursor-pointer" fill="currentColor" viewBox="0 0 24 24">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
            </svg>
          </a>

          {/* Language Toggle */}
          <button 
            onClick={() => setLanguage(language === 'en' ? 'es' : 'en')}
            className="hidden md:block text-sm hover:text-accent transition-colors cursor-pointer font-neutrif"
          >
            {language === 'en' ? 'ES' : 'EN'}
          </button>

          {/* Contact Button */}
          <a 
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=mq_X0h3k40uRzGIUBFdzADORfNkkVx1LiNTmTD2_qVFUQjg1OUcwQTM5U0VKNkw5SldER0gzSURZQy4u"
            target="_blank"
            rel="noopener noreferrer"
            className="hidden md:block px-6 py-2 border border-white/20 hover:bg-accent hover:border-accent transition-colors tracking-wide cursor-pointer font-neutrif"
          >
            {translations.nav.workWithUs}
          </a>

          {/* Mobile Menu Button */}
          <button 
            onClick={() => setIsMobileMenuOpen(true)}
            className="md:hidden cursor-pointer"
          >
            <svg className="w-6 h-6 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div 
        className={`fixed inset-0 bg-background/95 backdrop-blur-sm transition-transform duration-300 ease-in-out transform ${
          isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } md:hidden`}
      >
        <div className="h-full flex flex-col p-8">
          <div className="flex justify-end mb-8">
            <button 
              onClick={() => setIsMobileMenuOpen(false)}
              className="p-2 cursor-pointer"
            >
              <svg className="w-6 h-6 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <div className="flex flex-col space-y-6 text-2xl">
            <a 
              href="#firm" 
              className="hover:text-accent transition-colors font-neutrif"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {translations.nav.firm}
            </a>
            <a 
              href="#services" 
              className="hover:text-accent transition-colors font-neutrif"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {translations.nav.services}
            </a>
            <a 
              href="#people" 
              className="hover:text-accent transition-colors font-neutrif"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {translations.nav.people}
            </a>
            <a 
              href="#testimonials" 
              className="hover:text-accent transition-colors font-neutrif"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {translations.nav.testimonials}
            </a>
          </div>

          <div className="mt-auto">
            {/* Language Toggle for Mobile */}
            <button 
              onClick={() => {
                setLanguage(language === 'en' ? 'es' : 'en');
                setIsMobileMenuOpen(false);
              }}
              className="w-full text-center py-4 hover:text-accent transition-colors text-lg mb-4 font-neutrif"
            >
              {language === 'en' ? 'ES' : 'EN'}
            </button>

            {/* LinkedIn Button for Mobile */}
            <a
              href="https://www.linkedin.com/company/gamboa-abogados/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-full py-4 hover:text-accent transition-colors mb-4"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
              </svg>
            </a>

            <a 
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=mq_X0h3k40uRzGIUBFdzADORfNkkVx1LiNTmTD2_qVFUQjg1OUcwQTM5U0VKNkw5SldER0gzSURZQy4u"
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full text-center py-4 bg-accent text-white hover:bg-accent/90 transition-colors cursor-pointer font-neutrif"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {translations.nav.workWithUs}
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 