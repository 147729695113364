import { useLanguage } from '../../context/LanguageContext';

const Testimonials = () => {
  const { translations } = useLanguage();

  const recognitions = [
    {
      source: "Chambers & Partners",
      year: "2025",
      quote: "",
      rating: "Band 3",
      area: "Dispute Resolution - Colombia",
      logo: "/awards/2025-chambers.png"
    },
    {
      source: "Chambers & Partners",
      year: "2024",
      quote: "",
      rating: "Band 3",
      area: "Dispute Resolution - Colombia",
      logo: "/awards/2024-chambers.png"
    },
    {
      source: "Legal 500",
      year: "2025",
      quote: "",
      rating: "Tier 4",
      area: "Litigation",
      logo: "/awards/2025-legal500.png"
    },
    {
      source: "Legal 500",
      year: "2023",
      quote: "",
      rating: "Tier 3",
      area: "Litigation",
      logo: "/awards/2023-legal500.png"
    },
    {
      source: "Leaders League",
      year: "2025",
      quote: "",
      rating: "Recommended Firm",
      area: "Dispute Resolution: Civil and Commercial Litigation - Colombia",
      logo: "/awards/2025-leaders-league.png"
    }
  ];

  const testimonials = [
    {
      quote: "Gamboa Abogados have expertly handled a litigation case involving multiple sophisticated stakeholders and counterparties. They have shown an incredible depth of expertise which has proven invaluable."
    },
    {
      quote: "The advice we receive is impressive. The response times are quick and the work is of an excellent quality."
    },
    {
      quote: "Ernesto Gamboa Morales is an expert in arbitration and commercial litigation, and his handling of public hearings is spectacular."
    },
    {
      quote: "Eduardo Gamboa has delivered a solid service. His advice has been critical in the development and delivery of a dispute strategy which has surpassed our expectations."
    }
  ];

  return (
    <section id="testimonials" className="px-8 py-16">
      <div className="container">
        <div className="border-t border-white/10 pt-8">
          <div className="flex justify-between items-start mb-24">
            <h2 className="font-neutrif font-light leading-[1.1] tracking-tight">
              <span className="brush-accent">{translations.testimonials.title.line1}</span>
              <br />
              {translations.testimonials.title.line2}
              <br />
              {translations.testimonials.title.line3}
            </h2>
          </div>

          {/* Recognitions Carousel */}
          <div className="relative">
            <div className="overflow-x-auto scrollbar-hide">
              <div className="flex gap-4 md:gap-6 pb-4">
                {recognitions.map((recognition, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-lg p-4 md:p-8 hover:shadow-lg transition-all group flex-none w-[85vw] sm:w-[45vw] md:w-[30vw]"
                  >
                    <div className="flex justify-between items-start mb-4 md:mb-6">
                      <div className="w-32 md:w-48 h-24 md:h-32 flex items-center justify-center">
                        <img
                          src={recognition.logo}
                          alt={recognition.source}
                          className="max-w-full max-h-full object-contain"
                        />
                      </div>
                      <span className="text-accent font-medium">{recognition.year}</span>
                    </div>
                    <div className="text-xs md:text-sm text-gray-700 mb-2">{recognition.area}</div>
                    <div className="text-xs md:text-sm text-gray-900 font-medium">{recognition.rating}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="absolute left-0 top-0 bottom-0 w-8 md:w-16 bg-gradient-to-r from-background to-transparent pointer-events-none" />
            <div className="absolute right-0 top-0 bottom-0 w-8 md:w-16 bg-gradient-to-l from-background to-transparent pointer-events-none" />
          </div>

          {/* Client Testimonials */}
          <div className="mt-32">
            <h3 className="font-neutrif font-light leading-tight mb-16">
              <span className="brush-accent">{translations.testimonials.clientsTitle}</span>
            </h3>
            <div className="grid md:grid-cols-2 gap-x-24 gap-y-16">
              {testimonials.map((testimonial, index) => (
                <div key={index} className="relative">
                  <div className="text-4xl text-accent mb-8">"</div>
                  <p className="text-xl text-gray-400 mb-8 leading-relaxed">
                    {testimonial.quote}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials; 