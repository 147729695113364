import { useState } from 'react';
import Modal from '../ui/Modal';
import { useLanguage } from '../../context/LanguageContext';

interface Service {
  id: string;
  title: string;
  shortPhrase: string;
  description: string;
  longDescription: string;
  expertise: string[];
}

interface ServiceCardProps {
  service: Service;
}

const ServiceCard = ({ service }: ServiceCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { translations } = useLanguage();

  return (
    <>
      {/* Preview Card */}
      <div 
        className="bg-white/5 p-8 rounded-sm cursor-pointer hover:bg-white/10 transition-colors group"
        onClick={() => setIsModalOpen(true)}
      >
        <h3 className="text-lg md:text-xl font-light tracking-[-0.01em] mb-3">{service.title}</h3>
        <p className="text-accent text-sm mb-4 font-light tracking-wide">{service.shortPhrase}</p>
        <p className="text-gray-400 text-sm leading-relaxed mb-6">{service.description}</p>
        <div className="flex flex-wrap gap-2">
          {service.expertise.slice(0, 3).map((item, i) => (
            <span key={i} className="px-2.5 py-1 border border-white/10 text-xs tracking-wide hover:bg-white/10 transition-colors">
              {item}
            </span>
          ))}
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="space-y-10">
          {/* Header */}
          <div>
            <h2 className="text-xl md:text-2xl font-light tracking-[-0.01em] mb-3">{service.title}</h2>
            <p className="text-accent text-base mb-4 font-light tracking-wide">{service.shortPhrase}</p>
            <p className="text-gray-400 text-sm leading-relaxed whitespace-pre-line">{service.longDescription}</p>
          </div>

          {/* Expertise */}
          <div>
            <h3 className="text-lg font-light tracking-[-0.01em] mb-4">{translations.services.areasOfExpertise}</h3>
            <div className="flex flex-wrap gap-2">
              {service.expertise.map((item, i) => (
                <span key={i} className="px-2.5 py-1 bg-white/5 text-xs tracking-wide hover:bg-white/10 transition-colors">
                  {item}
                </span>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ServiceCard; 