import { useLanguage } from '../../context/LanguageContext';

const Hero = () => {
  const { translations, language } = useLanguage();

  return (
    <section className="min-h-screen px-8 flex items-center relative">
      <div className="container">
        <div className="grid md:grid-cols-2 items-center gap-8">
          <div>
            <h1 className="mb-12 md:mb-20">
              {translations.hero.title.line1}
              <br />
              {translations.hero.title.line2} <span className="brush-accent">{translations.hero.title.line3}</span>
              <br />
              {translations.hero.title.line4}
            </h1>

            <div className="flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-6">
              <a 
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=mq_X0h3k40uRzGIUBFdzADORfNkkVx1LiNTmTD2_qVFUQjg1OUcwQTM5U0VKNkw5SldER0gzSURZQy4u"
                target="_blank"
                rel="noopener noreferrer"
                className="hero-btn hero-btn-primary w-full md:w-auto cursor-pointer hover:bg-accent/90 transition-colors"
              >
                {translations.hero.cta.contact}
              </a>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <div className="relative w-full max-w-md md:max-w-none">
              <img 
                src="/mast.png"
                alt={language === 'en' 
                  ? "Gamboa Abogados law firm logo featuring a ship mast, symbolizing navigation through legal complexity"
                  : "Logo de Gamboa Abogados con un mástil de barco, simbolizando la navegación a través de la complejidad legal"
                }
                className="w-full h-auto object-contain opacity-90 brightness-200 contrast-125 saturate-150 hover:opacity-100 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 border-t border-white/10" />
    </section>
  );
};

export default Hero; 