import { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../ui/Modal';
import { useLanguage } from '../../context/LanguageContext';

interface Recognition {
  year: string;
  rating: string;
  quote: string;
  logo: string;
}

interface Attorney {
  id: string;
  name: string;
  title: string;
  bio: string;
  image: string;
  education: string[];
  experience: string[];
  languages: string[];
  email: string;
  linkedin?: string;
  recognitions?: Recognition[];
}

interface AttorneyCardProps {
  attorney: Attorney;
}

const AttorneyCard = ({ attorney }: AttorneyCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { language } = useLanguage();

  const sectionTitles = {
    en: {
      contact: 'Contact',
      recognition: 'Individual Recognition',
      about: 'About',
      education: 'Education',
      experience: 'Experience',
      languages: 'Languages'
    },
    es: {
      contact: 'Contacto',
      recognition: 'Reconocimiento Individual',
      about: 'Perfil',
      education: 'Educación',
      experience: 'Experiencia',
      languages: 'Idiomas'
    }
  };

  const titles = sectionTitles[language];

  const handleImageError = () => {
    setImageError(true);
    setImageLoaded(true);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const getImageUrl = () => {
    if (imageError) {
      return '/placeholder-attorney.jpg';
    }
    // Try to use the optimized image
    const baseName = attorney.image.split('/').pop()?.replace(/\.[^/.]+$/, '');
    return `/attorneys/optimized/${baseName}.jpg`;
  };

  return (
    <>
      {/* Preview Card */}
      <div className="group cursor-pointer" onClick={() => setIsModalOpen(true)}>
        <div className="aspect-[4/3] mb-8 bg-white/5 overflow-hidden relative">
          {!imageLoaded && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-8 h-8 border-2 border-accent border-t-transparent rounded-full animate-spin"></div>
            </div>
          )}
          <img
            src={getImageUrl()}
            alt={attorney.name}
            onError={handleImageError}
            onLoad={handleImageLoad}
            loading="lazy"
            className={`w-full h-full object-cover transition-all duration-500 cursor-pointer ${
              imageLoaded
                ? 'opacity-80 group-hover:opacity-100'
                : 'opacity-0'
            }`}
          />
        </div>
        
        <h3 className="font-neutrif text-2xl font-light mb-2 cursor-pointer">{attorney.name}</h3>
        <p className="text-accent mb-4 cursor-pointer">{attorney.title}</p>
        <p className="text-gray-400 leading-relaxed line-clamp-3 cursor-pointer">{attorney.bio}</p>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="grid md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div>
            <div className="aspect-[3/4] bg-white/5 overflow-hidden relative mb-4">
              {!imageLoaded && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-8 h-8 border-2 border-accent border-t-transparent rounded-full animate-spin"></div>
                </div>
              )}
              <img
                src={getImageUrl()}
                alt={attorney.name}
                onError={handleImageError}
                onLoad={handleImageLoad}
                loading="lazy"
                className={`w-full h-full object-cover transition-opacity duration-500 ${
                  imageLoaded ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </div>
            <div className="flex items-center space-x-4">
              <a 
                href={`mailto:${attorney.email}`}
                className="flex-1 text-center py-2 bg-accent text-white hover:bg-accent/90 transition-colors cursor-pointer"
              >
                {titles.contact}
              </a>
              {attorney.linkedin && (
                <a 
                  href={attorney.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 hover:text-accent transition-colors cursor-pointer"
                >
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                  </svg>
                </a>
              )}
            </div>

            {/* Chambers Recognitions */}
            {attorney.recognitions && attorney.recognitions.length > 0 && (
              <div className="mt-6">
                <h3 className="font-neutrif font-light mb-4">{titles.recognition}</h3>
                <div className="space-y-4">
                  {attorney.recognitions.map((recognition, index) => (
                    <div key={index} className="bg-white/5 p-4 hover:bg-white/10">
                      <div className="flex justify-between items-center mb-3">
                        <div className="w-36 h-20 flex items-center justify-center">
                          <img
                            src={recognition.logo}
                            alt="Chambers & Partners"
                            className="award-logo"
                          />
                        </div>
                        <span className="text-accent">{recognition.year}</span>
                      </div>
                      <p className="text-sm text-gray-400 mb-2">{recognition.quote}</p>
                      <div className="text-sm text-accent">{recognition.rating}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <div>
              <h2 className="font-neutrif font-light mb-2">{attorney.name}</h2>
              <p className="text-accent text-lg">{attorney.title}</p>
            </div>

            <div>
              <h3 className="font-neutrif font-light mb-2">{titles.about}</h3>
              <p className="text-gray-400 leading-relaxed">{attorney.bio}</p>
            </div>

            <div>
              <h3 className="font-neutrif font-light mb-2">{titles.education}</h3>
              <ul className="space-y-2">
                {attorney.education.map((edu, index) => (
                  <li key={index} className="text-gray-400 hover:text-white">{edu}</li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="font-neutrif font-light mb-2">{titles.experience}</h3>
              <ul className="space-y-2">
                {attorney.experience.map((exp, index) => (
                  <li key={index} className="text-gray-400 hover:text-white">{exp}</li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="font-neutrif font-light mb-2">{titles.languages}</h3>
              <div className="flex flex-wrap gap-2">
                {attorney.languages.map((lang, index) => (
                  <span key={index} className="px-3 py-1 bg-white/5 text-sm hover:bg-white/10">
                    {lang}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AttorneyCard; 