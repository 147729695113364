export const en = {
  nav: {
    firm: 'The Firm',
    services: 'Services',
    people: 'People',
    contact: 'Contact',
    workWithUs: 'Contact Us',
    testimonials: 'Recognitions'
  },
  hero: {
    title: {
      line1: 'We navigate through legal',
      line2: 'complexity to deliver',
      line3: 'business-ready',
      line4: 'solutions'
    },
    cta: {
      contact: 'Contact Us'
    }
  },
  firm: {
    title: {
      line1: 'We partner with our clients to',
      line2: 'design solutions',
      line3: 'to their',
      line4: 'most pressing challenges'
    },
    description1: 'We don\'t just handle legal cases. We design legally-enabled, effective solutions. We start by understanding the most valuable ways to solve our clients\' challenges, then work with them to bring those solutions to life in a way that puts their business objectives at its peak.',
    description2: 'To build a legal solution, we look beyond the needs of the current moment. We plan ahead to make it intelligent and adaptable as it grows. Drawing on seven generations of legal tradition, we ensure your business gets the exact tools needed to evolve.',
    principles: {
      title: {
        line1: 'We operate on a',
        line2: 'foundation of three',
        line3: 'crucial principles...'
      },
      list: [
        {
          title: 'Tradition meets innovation',
          description: 'Seven generations of legal expertise combined with modern methodologies. We blend time-tested principles with innovative approaches to create solutions that work for today\'s business landscape.'
        },
        {
          title: 'Proactive strategy',
          description: 'We anticipate and prevent legal challenges before they arise. Our approach saves clients time, resources, and reputation by addressing potential issues at their source.'
        },
        {
          title: 'Business-first mindset',
          description: 'We understand that legal solutions must serve business objectives. Every strategy we develop considers the broader business context and industry-specific factors that impact our clients.'
        }
      ]
    }
  },
  services: {
    title: 'Our Services',
    areasOfExpertise: 'Areas of Expertise',
    disputeResolution: {
      title: 'Dispute Resolution',
      description: 'We combine generations of legal expertise with modern methodologies to resolve complex commercial disputes effectively and efficiently.',
      industries: ['Infrastructure', 'Insurance', 'Construction', 'Ports', 'Airports', 'IT', 'Sports', 'TV Rights']
    },
    corporate: {
      title: 'Corporate Advisory',
      description: 'As an exclusive service for our longstanding clients, we provide strategic legal counsel on commercial and corporate matters, ensuring compliance and minimizing risk.',
      specialties: ['Contract Negotiation', 'Corporate Governance', 'Regulatory Compliance', 'Risk Management', 'Strategic Planning', 'Business Development']
    }
  },
  testimonials: {
    title: {
      line1: 'Latest',
      line2: 'Recognitions',
      line3: ''
    },
    clientsTitle: 'What our clients say',
    recognitions: [
      {
        source: 'Chambers & Partners',
        year: '2023',
        quote: 'Outstanding dispute resolution practice with exceptional expertise in commercial litigation.',
        rating: 'Band 1'
      },
      {
        source: 'Legal 500',
        year: '2023',
        quote: 'Top-tier firm for commercial advisory and dispute resolution.',
        rating: 'Tier 1'
      },
      {
        source: 'Best Lawyers',
        year: '2023',
        quote: 'Leading firm in corporate law and commercial litigation.',
        rating: 'Excellence'
      }
    ],
    clients: [
      {
        client: 'Global Infrastructure Corp',
        position: 'General Counsel',
        quote: 'Their proactive approach to legal challenges has saved us countless hours and resources.'
      },
      {
        client: 'Tech Innovations Ltd',
        position: 'CEO',
        quote: 'They understand not just the law, but how it applies to our business objectives.'
      }
    ]
  },
  people: {
    title: {
      line1: 'Meet our',
      line2: 'team',
    },
    viewProfile: 'View Profile'
  }
}; 