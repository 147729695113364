import { useLanguage } from '../../context/LanguageContext';
import AttorneyCard from '../attorneys/AttorneyCard';

interface Recognition {
  year: string;
  rating: string;
  quote: string;
  logo: string;
}

interface Attorney {
  id: string;
  name: string;
  title: string;
  bio: string;
  image: string;
  education: string[];
  experience: string[];
  languages: string[];
  email: string;
  linkedin?: string;
  recognitions?: Recognition[];
}

const People = () => {
  const { translations, language } = useLanguage();

  const attorneys = {
    en: [
      {
        id: 'ernesto-gamboa',
        name: 'Ernesto Gamboa Morales',
        title: 'Founding Partner',
        bio: 'Expert in civil law, commercial law, procedural law, and in litigation, national and international arbitration, and negotiation. He has been legal advisor for over 35 years to numerous companies and entities in the ports, chemicals, infrastructure, sports and entertainment sectors, among others. He has also acted in more than 60 national and international commercial arbitrations, both as counsel and arbitrator.',
        image: '/attorneys/optimized/ernesto-gamboa.jpg',
        education: [
          'J.D., Colegio Mayor de Nuestra Señora del Rosario (1976)',
          'Specializations in Private Law, Public Law and Procedural Law, Colegio Mayor de Nuestra Señora del Rosario',
          'LL.M. in Taxation, Universidad de Los Andes',
          'Negotiation and Conciliation Techniques, Harvard University and University College London'
        ],
        experience: [
          'Professor at Universidad de la Sabana, Universidad Sergio Arboleda and Colegio Mayor de Nuestra Señora del Rosario',
          'Member of the Colombian Academy of Jurisprudence',
          'Member of the Colombian Institute of Procedural Law',
          'Arbitrator of List A of the Bogotá Chamber of Commerce',
          'Colombian Arbitrator at the Court of Arbitration for Sport in Lausanne (CAS)',
          'Author of numerous publications including the book "Arbitration in Equity"'
        ],
        languages: ['Spanish', 'English'],
        email: 'egamboa@galegal.co',
        linkedin: 'https://www.linkedin.com/in/ernesto-gamboa',
        recognitions: [
          {
            year: '2024',
            rating: 'Band 3',
            quote: 'Individual Ranking Dispute Resolution, Chambers & Partners',
            logo: '/awards/ernesto-gamboa-recognition.png'
          }
        ]
      },
      {
        id: 'eduardo-gamboa',
        name: 'Eduardo Gamboa Mahecha',
        title: 'Partner',
        bio: 'Focuses his practice on civil, commercial and procedural law. Partner at Gamboa Abogados since 2017. Has participated in numerous civil, commercial and administrative proceedings; national and international arbitrations, related to the ports, infrastructure, sports and entertainment industries, among others. Has also advised clients in various mergers and acquisitions processes.',
        image: '/attorneys/optimized/eduardo-gamboa.jpg',
        education: [
          'J.D. cum laude, Universidad de los Andes (2013)',
          'Specialization in International Business Law, Universidad de los Andes (2016)',
          'Maestría en Derecho (LL.M.) with emphasis in Legal Theory, London School of Economics and Political Science (2019)'
        ],
        experience: [
          'Professor of Civil Procedure at Universidad de los Andes',
          'Expert in civil and commercial litigation',
          'Specialist in national and international arbitration',
          'Advisor on complex commercial transactions'
        ],
        languages: ['Spanish', 'English'],
        email: 'eduardo.gamboa@galegal.co',
        linkedin: 'https://www.linkedin.com/in/eduardo-gamboa-5401137b/',
        recognitions: [
          {
            year: '2024',
            rating: 'Up and coming',
            quote: 'Individual Ranking Dispute Resolution, Chambers & Partners',
            logo: '/awards/eduardo-gamboa-recognition.png'
          }
        ]
      },
      {
        id: 'paula-isaza',
        name: 'Paula Isaza de Zubiria',
        title: 'Partner',
        bio: 'Focuses on advising companies in the chemical and oil industries, and the sports and entertainment sector, among others. Her practice concentrates on providing legal services in commercial law, intellectual property, competition law and labor law matters, with vast experience in sports industry contracts. Until 2014, she worked in Avianca\'s legal team and since then has been part of Gamboa Abogados.',
        image: '/attorneys/optimized/paula-isaza.jpg',
        education: [
          'J.D., Universidad de los Andes (2013)',
          'Specialization in International Business Law, Universidad de los Andes (2016)',
          'Maestría en Derecho (LL.M.) with emphasis in Art and Business, Queen Mary University of London (2019)'
        ],
        experience: [
          'Legal advisor for chemical and oil industry companies',
          'Former member of Avianca\'s legal team (until 2014)',
          'Specialist in intellectual property and competition law matters',
          'Expert in labor law consulting',
          'Extensive experience in sports industry contracts'
        ],
        languages: ['Spanish', 'English'],
        email: 'pisaza@galegal.co',
        linkedin: 'https://www.linkedin.com/in/paula-isaza-de-zubiria-6422302a/'
      },
      {
        id: 'santiago-marulanda',
        name: 'Santiago Marulanda Mürrle',
        title: 'Associate',
        bio: 'Lawyer from Pontificia Universidad Javeriana with emphasis in international commercial law and tort law (2020), and Maestría en Derecho (LL.M.) at King\'s College London (2024). Has been with the firm since 2015. Has experience in national and international arbitration proceedings, under the rules of the ICC, the Colombian Arbitration Statute and the rules of the Bogotá Chamber of Commerce. Has participated in arbitration award annulment proceedings before the Supreme Court of Justice and the Council of State. Additionally, has assisted arbitrators in proceedings before the Court of Arbitration for Sport (CAS) in Lausanne, Switzerland.',
        image: '/attorneys/optimized/santiago-marulanda.jpg',
        education: [
          'J.D., Pontificia Universidad Javeriana with emphasis in International Commercial Law and Civil Liability (2020)',
          'Maestría en Derecho (LL.M.), King\'s College London (2024)'
        ],
        experience: [
          'Associate at Gamboa Abogados since 2015',
          'Co-author of "Ordinary and Appeal Procedures before the Court of Arbitration for Sport" in Daniel Crespo (ed.), Sports Law, National and International Football Regulation (1st edition, Buenos Aires: Editorial Estudio, 2024)',
          'Co-author of "A Preliminary Look at Sports Arbitration" published in the third edition of Arbitrio Magazine by the Arbitration Center of the Bogotá Chamber of Commerce'
        ],
        languages: ['Spanish', 'English', 'German'],
        email: 'smarulanda@galegal.co',
        linkedin: 'https://www.linkedin.com/in/santiago-marulanda-m%C3%BCrrle-b680a61b0/'
      },
      {
        id: 'miguel-bedoya',
        name: 'Miguel Angel Bedoya Puerta',
        title: 'Associate',
        bio: 'Lawyer from Colegio Mayor de Nuestra Señora del Rosario with a LL.M. in Administrative Law from the same university. Since October 2021, he has been an associate lawyer at Gamboa Abogados, focusing on litigation and national and international arbitration. Has handled processes before the ordinary jurisdiction in civil and commercial law matters, as well as extraordinary appeals for cassation; and processes before the administrative contentious jurisdiction.',
        image: '/attorneys/optimized/miguel-bedoya.jpg',
        education: [
          'J.D., Colegio Mayor de Nuestra Señora del Rosario (2017)',
          'LL.M. in Administrative Law, Colegio Mayor de Nuestra Señora del Rosario (2024)',
          'Course on Secretary of National Arbitration Tribunals, Bogotá Chamber of Commerce (2024)'
        ],
        experience: [
          'Associate at Gamboa Abogados since 2021',
          'Expert in civil and commercial litigation',
          'Experience in extraordinary appeals for cassation',
          'Specialist in administrative contentious jurisdiction',
          'LL.M. thesis on arbitration award annulment'
        ],
        languages: ['Spanish', 'English'],
        email: 'mbedoya@galegal.co',
        linkedin: 'https://www.linkedin.com/in/miguel-angel-bedoya-puerta-41648b9a/'
      },
      {
        id: 'alejandro-gomez',
        name: 'Alejandro Gómez Guerrero',
        title: 'Associate',
        bio: 'Lawyer from Pontificia Universidad Javeriana (2019). Has worked in multiple areas of law, including dispute resolution, foreign trade and transportation. Has extensive experience in real estate and infrastructure litigation, as well as contract negotiation. Previously worked at the Ministry of Commerce and Posse Herrera Ruiz.',
        image: '/attorneys/optimized/alejandro-gomez.jpg',
        education: [
          'J.D., Pontificia Universidad Javeriana (2019)',
          'Professor of international commercial law courses at Kenyatta University in Nairobi and Pontificia Universidad Javeriana in Bogotá'
        ],
        experience: [
          'Former lawyer at the Ministry of Commerce',
          'Former associate at Posse Herrera Ruiz',
          'Best Speaker Award at the prestigious John H. Jackson Moot Court Competition (2019)',
          'Received the "Best Saber Pro 2019" award from the Ministry of Education for achieving the highest scores in the state exam'
        ],
        languages: ['Spanish', 'English', 'French'],
        email: 'agomez@galegal.co',
        linkedin: 'https://www.linkedin.com/in/alejandro-gomez-3623a4194/'
      },
      {
        id: 'nathalia-tovar',
        name: 'Nathalia Tovar Hernández',
        title: 'Associate',
        bio: 'Lawyer from Pontificia Universidad Javeriana (2021) with a specialization in Business Law from Universidad Externado de Colombia (2024). Her practice focuses on corporate, contractual, and M&A consulting, primarily advising on corporate structuring, corporate governance, compliance with routine obligations, and contract negotiation. She has also participated in special mergers and acquisitions projects. Before joining Gamboa Abogados, she worked at a renowned law firm and as in-house counsel at Avianca S.A.',
        image: '/attorneys/optimized/nathalia-tovar.jpg',
        education: [
          'J.D., Pontificia Universidad Javeriana (2021)',
          'Specialization in Business Law, Universidad Externado de Colombia (2024)'
        ],
        experience: [
          'Corporate law and M&A specialist',
          'Former member of Avianca S.A.\'s corporate and M&A legal department',
          'Experience in company incorporation and liquidation processes',
          'Expert in corporate governance and board matters',
          'Publication of thesis on Failed States and International Law (2020)'
        ],
        languages: ['Spanish', 'English'],
        email: 'ntovar@galegal.co',
        linkedin: 'https://www.linkedin.com/in/nathalia-tovar-hernandez-97069a122/'
      },
      {
        id: 'juan-felipe-rios',
        name: 'Juan Felipe Rios Duarte',
        title: 'Associate',
        bio: 'Lawyer from Universidad de los Andes (2022), specialist in Commercial Law (2023) and LL.M. in Private Law from the same university (2024). His practice focuses on commercial law, with special interest in corporate litigation and corporate law. Currently an associate at Gamboa Abogados, where he advises national and international clients on corporate law and dispute resolution matters. Has experience in drafting corporate bylaws, preparing petitions, accompanying judicial processes and drafting judicial orders. Previously, he worked as a reporting lawyer in the Delegation of Commercial Procedures of the Superintendence of Companies, supporting the development of judicial processes and drafting judicial orders.',
        image: '/attorneys/optimized/juan-felipe-rios.jpg',
        education: [
          'J.D., Universidad de los Andes (2022)',
          'Specialization in Commercial Law, Universidad de los Andes (2023)',
          'LL.M. in Private Law, Universidad de los Andes (2024)',
          'Diploma in Legal M&A and Corporate Transactions, Universidad de los Andes (2022)'
        ],
        experience: [
          'Merit letter awarded by the Faculty of Law of Universidad de los Andes for the academic quality of his thesis: "Compensation for damages in divorce proceedings. An analysis from judgment SU/080 of 2020"',
          'Participant in Procedural Law and Corporate Law research seedbeds led by María del Socorro Rueda, Alfredo Rey and Sergio Londoño',
          'Representative to the Uniandino Student Council in 2021'
        ],
        languages: ['Spanish', 'English'],
        email: 'jfrios@galegal.co',
        linkedin: 'https://www.linkedin.com/in/juan-felipe-r%C3%ADos-duarte-337689211/'
      },
      {
        id: 'juliana-indaburu',
        name: 'Juliana Indaburu Piazzini',
        title: 'External Consultant',
        bio: 'Lawyer specializing in Family Law from Pontificia Universidad Javeriana (2022). During her studies, she was class representative and member of the Family Law Specialization Steering Committee. Her practice focuses on family law, advising and accompanying clients in extrajudicial and judicial processes related to divorces, cessation of civil effects of de facto marital unions, alimony establishment, liquidation of conjugal or patrimonial partnerships, declarations of de facto marital unions, estate planning processes for individuals and family companies, and successions.',
        image: '/attorneys/optimized/juliana-indaburu.jpg',
        education: [
          'J.D., Pontificia Universidad Javeriana (2021)',
          'Specialization in Family Law (2023)',
          'Divorce Mediation Training, Northwestern University in Chicago (2024)',
          'Diplomado en Arbitraje Nacional e Internacional, Cámara de Comercio de Bogotá (2018)',
          'Ciclo de seminarios: Régimen sustancial y procesal de las sucesiones en Colombia, Universidad del Rosario (2023)',
          'Seminario de planeación tributaria de empresas familiares, Universidad del Rosario (2024)'
        ],
        experience: [
          'Coordinator of the Family Area at the Private Law Observatory'
        ],
        languages: ['Spanish', 'English', 'Italian'],
        email: 'jindaburu@galegal.co',
        linkedin: 'https://www.linkedin.com/in/juliana-indaburu-8368271b6/'
      },
      {
        id: 'lourdes-jimenez',
        name: 'Lourdes Ivonne Jiménez-Mendivil',
        title: 'Associate',
        bio: 'Focuses her practice on financial law, corporate law, and litigation. Has advised entities supervised by the Financial Superintendence on regulatory matters and the promotion of financial products in Colombia. Has also participated in civil and commercial proceedings before ordinary courts, as well as in administrative proceedings and litigation against State authorities. In the corporate field, advises companies on legal opinions and the review of commercial and financial contracts.',
        image: '/attorneys/optimized/lourdes-jimenez.jpg',
        education: [
          'J.D., Universidad de los Andes (2022)',
          'B.A. in History, Universidad de los Andes (2023)',
          'Diploma in Finance for Non-Financial Professionals, Universidad de la Sabana (2023)',
          'Legal English Course, MPC Law Studio S.A.S. (2023)',
          'Specialization in Financial and Securities Market Law, Pontificia Universidad Javeriana (2025)'
        ],
        experience: [
          'Member of Muñoz Aya\'s legal team (2023)',
          'Financial and corporate law advisory. Preparation of legal opinions and review of commercial and financial contracts',
          'Member of Durán & Osorio\'s legal team (2022)',
          'Participation in civil and commercial litigation. Support in proceedings before ordinary courts and in administrative proceedings',
          'Intern at the Special Jurisdiction for Peace (2021)'
        ],
        languages: ['Spanish', 'English'],
        email: 'ljimenez@galegal.co',
        linkedin: 'https://www.linkedin.com/in/lourdes-ivonne-jim%C3%A9nez-mendivil/'
      },
      {
        id: 'domingo-ortiz',
        name: 'Domingo Ortíz Arroyo',
        title: 'Associate',
        bio: 'Lawyer from Universidad Católica de Colombia. Expert in monitoring judicial proceedings.',
        image: '/attorneys/optimized/domingo-ortiz.jpg',
        education: [
          'J.D., Universidad Católica de Colombia (2010)'
        ],
        experience: [
          'Over 30 years of experience in judicial matters at Gamboa Abogados (since 1993)',
          'Expert in monitoring judicial proceedings',
          'Specialist in civil and commercial litigation'
        ],
        languages: ['Spanish'],
        email: 'dortiz@galegal.co'
      }
    ],
    es: [
      {
        id: 'ernesto-gamboa',
        name: 'Ernesto Gamboa Morales',
        title: 'Socio Fundador',
        bio: 'Experto en las áreas de derecho civil, comercial, procesal y en el litigio, arbitraje nacional e internacional y negociación. Ha sido asesor jurídico durante más de 35 años de numerosas empresas y entidades en los sectores de puertos, químicos, infraestructura, deportes y entretenimiento, entre otros. También ha actuado en más de 60 arbitrajes comerciales nacionales e internacionales, tanto en el rol de apoderado como de árbitro.',
        image: '/attorneys/optimized/ernesto-gamboa.jpg',
        education: [
          'Abogado, Colegio Mayor de Nuestra Señora del Rosario (1976)',
          'Especializaciones en Derecho Privado, Derecho Público y Derecho Procesal, Colegio Mayor de Nuestra Señora del Rosario',
          'Maestría en Derecho Tributario, Universidad de Los Andes',
          'Técnicas de Negociación y Conciliación, Harvard University y University College London'
        ],
        experience: [
          'Profesor en la Universidad de la Sabana, Universidad Sergio Arboleda y Colegio Mayor de Nuestra Señora del Rosario',
          'Miembro de la Academia Colombiana de Jurisprudencia',
          'Miembro del Instituto Colombiano de Derecho Procesal',
          'Árbitro de la Lista A de la Cámara de Comercio de Bogotá',
          'Árbitro colombiano en el Tribunal de Arbitraje Deportivo en Lausanne (TAS)',
          'Autor de numerosas publicaciones dentro de las que cabe destacar el libro "El Arbitraje en Equidad"'
        ],
        languages: ['Español', 'Inglés'],
        email: 'egamboa@galegal.co',
        linkedin: 'https://www.linkedin.com/in/ernesto-gamboa',
        recognitions: [
          {
            year: '2024',
            rating: 'Banda 3',
            quote: 'Ranking Individual Resolución de Conflictos, Chambers & Partners',
            logo: '/awards/ernesto-gamboa-recognition.png'
          }
        ]
      },
      {
        id: 'eduardo-gamboa',
        name: 'Eduardo Gamboa Mahecha',
        title: 'Socio',
        bio: 'Enfoca su práctica en asuntos de derecho civil, comercial y procesal. Desde el año 2017 es socio de Gamboa Abogados. Ha participado en numerosos procesos civiles, comerciales y administrativos; arbitrajes nacionales e internacionales, relacionados con la industria de puertos, infraestructura, deportes y entretenimiento, entre otros. También ha asesorado clientes en varios procesos de fusiones y adquisiciones.',
        image: '/attorneys/optimized/eduardo-gamboa.jpg',
        education: [
          'Abogado cum laude, Universidad de los Andes (2013)',
          'Especialización en Derecho de los Negocios Internacionales, Universidad de los Andes (2016)',
          'Maestría en Derecho (LL.M.) con énfasis en Teoría Legal, London School of Economics and Political Science (2019)'
        ],
        experience: [
          'Profesor de Procedimiento Civil en la Universidad de los Andes',
          'Experto en litigio civil y comercial',
          'Especialista en arbitraje nacional e internacional',
          'Asesor en transacciones comerciales complejas'
        ],
        languages: ['Español', 'Inglés'],
        email: 'eduardo.gamboa@galegal.co',
        linkedin: 'https://www.linkedin.com/in/eduardo-gamboa-5401137b/',
        recognitions: [
          {
            year: '2024',
            rating: 'Próxima generación',
            quote: 'Ranking Individual Resolución de Conflictos, Chambers & Partners',
            logo: '/awards/eduardo-gamboa-recognition.png'
          }
        ]
      },
      {
        id: 'paula-isaza',
        name: 'Paula Isaza de Zubiria',
        title: 'Socia',
        bio: 'Dedica su práctica a asesorar empresas de la industria química y de petróleos, y del sector deportes y entretenimiento, entre otras. Su ejercicio se concentra en prestar servicios legales en asuntos de derecho comercial, propiedad intelectual, derecho de la competencia y derecho laboral, con vasta experiencia en contratos del sector deportes y entretenimiento. Hasta el año 2014 trabajó en el equipo legal de Avianca y desde ese año se vinculó a Gamboa Abogados.',
        image: '/attorneys/optimized/paula-isaza.jpg',
        education: [
          'Abogada, Universidad de los Andes (2013)',
          'Especialización en Derecho de los Negocios Internacionales, Universidad de los Andes (2016)',
          'Maestría en Derecho (LL.M.) con énfasis en Arte y Negocios, Queen Mary University of London (2019)'
        ],
        experience: [
          'Asesora legal para empresas de la industria química y de petróleos',
          'Ex miembro del equipo legal de Avianca (hasta 2014)',
          'Especialista en asuntos de propiedad intelectual y derecho de la competencia',
          'Experta en asesoría de derecho laboral',
          'Amplia experiencia en contratos del sector deportivo'
        ],
        languages: ['Español', 'Inglés'],
        email: 'pisaza@galegal.co',
        linkedin: 'https://www.linkedin.com/in/paula-isaza-de-zubiria-6422302a/'
      },
      {
        id: 'santiago-marulanda',
        name: 'Santiago Marulanda Mürrle',
        title: 'Asociado',
        bio: 'Abogado de la Pontificia Universidad Javeriana con énfasis en derecho comercial internacional y derecho de daños (2020), y Maestría en Derecho (LL.M.) en King\'s College London (2024). Cuenta con experiencia en procedimientos arbitrales nacionales e internacionales, bajo las reglas de la ICC, el Estatuto Arbitral Colombiano y las reglas de la Cámara de Comercio de Bogotá. Ha participado en solicitudes de anulación ante la Corte Suprema de Justicia y el Consejo de Estado. Además, ha asistido a árbitros en procedimientos ante el Tribunal de Arbitraje Deportivo (TAS) en Lausana, Suiza.',
        image: '/attorneys/optimized/santiago-marulanda.jpg',
        education: [
          'Abogado, Pontificia Universidad Javeriana con énfasis en Derecho Comercial Internacional y Derecho de Daños (2020)',
          'Maestría en Derecho (LL.M.), King\'s College London (2024)'
        ],
        experience: [
          'Asociado en Gamboa Abogados desde 2015',
          'Coautor de "Procedimientos Ordinarios y de Apelación ante el Tribunal de Arbitraje Deportivo" en Daniel Crespo (ed.), Derecho Deportivo, Regulación Nacional e Internacional del Fútbol (1ra edición, Buenos Aires: Editorial Estudio, 2024)',
          'Coautor de "Una Mirada Preliminar al Arbitraje Deportivo" publicado en la tercera edición de la Revista Arbitrio del Centro de Arbitraje de la Cámara de Comercio de Bogotá'
        ],
        languages: ['Español', 'Inglés', 'Alemán'],
        email: 'smarulanda@galegal.co',
        linkedin: 'https://www.linkedin.com/in/santiago-marulanda-m%C3%BCrrle-b680a61b0/'
      },
      {
        id: 'miguel-bedoya',
        name: 'Miguel Angel Bedoya Puerta',
        title: 'Asociado',
        bio: 'Abogado del Colegio Mayor de Nuestra Señora del Rosario con Maestría en Derecho Administrativo de la misma universidad. Desde octubre de 2021, es abogado asociado en Gamboa Abogados, enfocándose en litigio y arbitraje nacional e internacional. Ha manejado procesos ante la jurisdicción ordinaria en asuntos de derecho civil y comercial, así como recursos extraordinarios de casación; y procesos ante la jurisdicción contencioso administrativa.',
        image: '/attorneys/optimized/miguel-bedoya.jpg',
        education: [
          'Abogado, Colegio Mayor de Nuestra Señora del Rosario (2017)',
          'Maestría en Derecho Administrativo, Colegio Mayor de Nuestra Señora del Rosario (2024)',
          'Curso de Secretario de Tribunales de Arbitraje Nacional, Cámara de Comercio de Bogotá (2024)'
        ],
        experience: [
          'Asociado en Gamboa Abogados desde 2021',
          'Experto en litigio civil y comercial',
          'Experiencia en recursos extraordinarios de casación',
          'Especialista en jurisdicción contencioso administrativa',
          'Tesis de Maestría sobre anulación de laudos arbitrales'
        ],
        languages: ['Español', 'Inglés'],
        email: 'mbedoya@galegal.co',
        linkedin: 'https://www.linkedin.com/in/miguel-angel-bedoya-puerta-41648b9a/'
      },
      {
        id: 'alejandro-gomez',
        name: 'Alejandro Gómez Guerrero',
        title: 'Asociado',
        bio: 'Abogado de la Pontificia Universidad Javeriana (2019). Ha trabajado en múltiples áreas del derecho, incluyendo resolución de conflictos, comercio exterior y transporte. Cuenta con amplia experiencia en litigios de bienes raíces e infraestructura, así como en negociación de contratos. Anteriormente trabajó en el Ministerio de Comercio y Posse Herrera Ruiz.',
        image: '/attorneys/optimized/alejandro-gomez.jpg',
        education: [
          'Abogado, Pontificia Universidad Javeriana (2019)',
          'Profesor de cursos de derecho comercial internacional en la Universidad Kenyatta en Nairobi y la Pontificia Universidad Javeriana en Bogotá'
        ],
        experience: [
          'Ex abogado del Ministerio de Comercio',
          'Ex asociado de Posse Herrera Ruiz',
          'Premio al Mejor Orador en la prestigiosa Competencia John H. Jackson Moot Court Competition (2019)',
          'Recibió el premio "Mejor Saber Pro 2019" del Ministerio de Educación por obtener los puntajes más altos en el examen de estado'
        ],
        languages: ['Español', 'Inglés', 'Francés'],
        email: 'agomez@galegal.co',
        linkedin: 'https://www.linkedin.com/in/alejandro-gomez-3623a4194/'
      },
      {
        id: 'nathalia-tovar',
        name: 'Nathalia Tovar Hernández',
        title: 'Asociada',
        bio: 'Abogada de la Pontificia Universidad Javeriana (2021) con especialización en Derecho de los Negocios de la Universidad Externado de Colombia (2024). Su práctica se enfoca en asesoría corporativa, contractual y M&A, principalmente en estructuración corporativa, gobierno corporativo, cumplimiento de obligaciones rutinarias y negociación de contratos. También ha participado en proyectos especiales de fusiones y adquisiciones. Antes de unirse a Gamboa Abogados, trabajó en una reconocida firma de abogados y como abogada interna en Avianca S.A.',
        image: '/attorneys/optimized/nathalia-tovar.jpg',
        education: [
          'Abogada, Pontificia Universidad Javeriana (2021)',
          'Especialización en Derecho de los Negocios, Universidad Externado de Colombia (2024)'
        ],
        experience: [
          'Especialista en derecho corporativo y M&A',
          'Ex miembro del departamento legal corporativo y M&A de Avianca S.A.',
          'Experiencia en procesos de constitución y liquidación de sociedades',
          'Experta en gobierno corporativo y asuntos de junta directiva',
          'Publicación de tesis sobre Estados Fallidos y Derecho Internacional (2020)'
        ],
        languages: ['Español', 'Inglés'],
        email: 'ntovar@galegal.co',
        linkedin: 'https://www.linkedin.com/in/nathalia-tovar-hernandez-97069a122/'
      },
      {
        id: 'juan-felipe-rios',
        name: 'Juan Felipe Rios Duarte',
        title: 'Asociado',
        bio: 'Abogado de la Universidad de los Andes (2022), especialista en Derecho Comercial (2023) y Magíster en Derecho Privado de la misma universidad (2024). Su práctica se enfoca en derecho comercial, con especial interés en litigio corporativo y derecho societario. Actualmente es asociado en Gamboa Abogados, donde asesora a clientes nacionales e internacionales en asuntos de derecho corporativo y resolución de conflictos. Tiene experiencia en la redacción de estatutos sociales, preparación de demandas, acompañamiento de procesos judiciales y redacción de providencias judiciales. Anteriormente, trabajó como abogado sustanciador en la Delegatura de Procedimientos Mercantiles de la Superintendencia de Sociedades, apoyando el desarrollo de procesos judiciales y la redacción de providencias.',
        image: '/attorneys/optimized/juan-felipe-rios.jpg',
        education: [
          'Abogado, Universidad de los Andes (2022)',
          'Especialización en Derecho Comercial, Universidad de los Andes (2023)',
          'Magíster en Derecho Privado, Universidad de los Andes (2024)',
          'Diplomado en M&A y Transacciones Corporativas, Universidad de los Andes (2022)'
        ],
        experience: [
          'Carta de mérito otorgada por la Facultad de Derecho de la Universidad de los Andes por la calidad académica de su tesis: "Indemnización de perjuicios en procesos de divorcio. Un análisis desde la sentencia SU/080 de 2020"',
          'Participante en semilleros de investigación de Derecho Procesal y Derecho Societario liderados por María del Socorro Rueda, Alfredo Rey y Sergio Londoño',
          'Representante al Consejo Estudiantil Uniandino en el periodo 2021'
        ],
        languages: ['Español', 'Inglés'],
        email: 'jfrios@galegal.co',
        linkedin: 'https://www.linkedin.com/in/juan-felipe-r%C3%ADos-duarte-337689211/'
      },
      {
        id: 'juliana-indaburu',
        name: 'Juliana Indaburu Piazzini',
        title: 'Consultora Externa',
        bio: 'Abogada especialista en Derecho de Familia de la Pontificia Universidad Javeriana (2022). Durante sus estudios, fue representante de curso y miembro del Comité de Dirección de la Especialización en Derecho de Familia. Su práctica se enfoca en el derecho de familia, asesorando y acompañando a clientes en procesos extrajudiciales y judiciales relacionados con divorcios, cesación de efectos civiles de unión marital de hecho, fijación de alimentos, liquidaciones de sociedad conyugal o patrimonial, declaraciones de unión marital de hecho, procesos de planeación patrimonial para personas naturales y sociedades familiares, y sucesiones.',
        image: '/attorneys/optimized/juliana-indaburu.jpg',
        education: [
          'Abogada, Pontificia Universidad Javeriana (2021)',
          'Especialización en Derecho de Familia (2023)',
          'Formación en Mediación de Divorcios, Northwestern University en Chicago (2024)',
          'Diplomado en Arbitraje Nacional e Internacional, Cámara de Comercio de Bogotá (2018)',
          'Ciclo de seminarios: Régimen sustancial y procesal de las sucesiones en Colombia, Universidad del Rosario (2023)',
          'Seminario de planeación tributaria de empresas familiares, Universidad del Rosario (2024)'
        ],
        experience: [
          'Coordinadora del Área de Familia del Observatorio de Derecho Privado'
        ],
        languages: ['Español', 'Inglés', 'Italiano'],
        email: 'jindaburu@galegal.co',
        linkedin: 'https://www.linkedin.com/in/juliana-indaburu-8368271b6/'
      },
      {
        id: 'lourdes-jimenez',
        name: 'Lourdes Ivonne Jiménez-Mendivil',
        title: 'Asociada',
        bio: 'Enfoca su práctica en derecho financiero, corporativo y litigio. Ha asesorado a entidades vigiladas por la Superintendencia Financiera en asuntos regulatorios y en la promoción de productos financieros en Colombia. También ha participado en procesos civiles y comerciales ante la jurisdicción ordinaria, así como en actuaciones administrativas y litigios frente a autoridades del Estado. En el ámbito corporativo, asesora a empresas en la elaboración de conceptos jurídicos y la revisión de contratos comerciales y financieros.',
        image: '/attorneys/optimized/lourdes-jimenez.jpg',
        education: [
          'Abogada, Universidad de los Andes (2022)',
          'Historiadora, Universidad de los Andes (2023)',
          'Diplomado en Finanzas para no Financieros, Universidad de la Sabana (2023)',
          'Curso de Legal English, MPC Law Studio S.A.S. (2023)',
          'Especialización en Derecho Financiero y del Mercado de Valores, Pontificia Universidad Javeriana (2025)'
        ],
        experience: [
          'Miembro del equipo legal de Muñoz Aya (2023)',
          'Asesoría en derecho financiero y corporativo. Elaboración de conceptos jurídicos y revisión de contratos comerciales y financieros',
          'Miembro del equipo legal de Durán & Osorio (2022)',
          'Participación en litigios civiles y comerciales. Apoyo en procesos ante la jurisdicción ordinaria y en actuaciones administrativas',
          'Practicante de la Jurisdicción Especial para la Paz (2021)'
        ],
        languages: ['Español', 'Inglés'],
        email: 'ljimenez@galegal.co',
        linkedin: 'https://www.linkedin.com/in/lourdes-ivonne-jim%C3%A9nez-mendivil/'
      },
      {
        id: 'domingo-ortiz',
        name: 'Domingo Ortíz Arroyo',
        title: 'Asociado',
        bio: 'Abogado de la Universidad Católica de Colombia. Experto en seguimiento de procesos judiciales.',
        image: '/attorneys/optimized/domingo-ortiz.jpg',
        education: [
          'Abogado, Universidad Católica de Colombia (2010)'
        ],
        experience: [
          'Más de 30 años de experiencia en asuntos judiciales en Gamboa Abogados (desde 1993)',
          'Experto en seguimiento de procesos judiciales',
          'Especialista en litigio civil y comercial'
        ],
        languages: ['Español'],
        email: 'dortiz@galegal.co'
      }
    ]
  };

  return (
    <section id="people" className="py-32">
      <div className="container px-8">
        <h2 className="font-neutrif text-5xl font-light mb-16">
          {translations.people.title.line1}
          <br />
          {translations.people.title.line2}
          <br />
          {translations.people.title.line3}
        </h2>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {attorneys[language].map((attorney) => (
            <AttorneyCard key={attorney.id} attorney={attorney} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default People; 